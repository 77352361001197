import { Container, Box, Grid } from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';

const PaymentOptions = ({
  activeButton,
  instruments,
  payWithInstrument,
  setPayWithInstrument,
}) => {

  if (!instruments) {
    return <div></div>;
  }

  let modifiedInstruments = [...instruments];
  const esewaIndex = instruments.findIndex((instrument) => instrument.instrumentName == "Esewa");
  if (esewaIndex !== -1) {
    const esewaInstrument = modifiedInstruments.splice(esewaIndex, 1)[0];
    modifiedInstruments.splice(0, 0, esewaInstrument);
  }
  const khaltiIndex = modifiedInstruments.findIndex((instrument) => instrument.instrumentName == "Khalti Gateway");

  if (khaltiIndex !== -1) {
    const khatiInstrument = modifiedInstruments.splice(khaltiIndex, 1)[0];
    modifiedInstruments.splice(1, 0, khatiInstrument);
  }

  // if connectIPS is available
  // const connectIpsIndex = instruments.findIndex((instrument) => instrument.instrumentName == "Connect IPS");
  return (
    <Container sx={{ marginTop: "2rem", paddingLeft: { xs: "0", sm: "24px", md: "24px", lg: "24px" }, paddingRight: { xs: "0", sm: "24px", md: "24px", lg: "24px" }, width: "92%" }} >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        {modifiedInstruments?.map((instrument) => (
          <Box
            key={instrument.instrumentCode}
            sx={{
              borderRadius: 4,
              padding: "8px 14px",
              backgroundColor: "#8B0000",
              borderColor: "#8B0000",
              boxShadow: "0px 0px 10px rgba(33, 109, 187, 0.18)",
              marginBottom: "1rem",
              marginRight: { xs: "7%", sm: "3%", md: "3%", lg: "4%" },
              cursor: "pointer",
              display: "flex",

              gap: "6px",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": {
                borderColor: "dodgerblue",
              },
              background:
                payWithInstrument === instrument
                  ? "rgba(139, 0, 0, 0.5)"
                  : "white",
              color: payWithInstrument === instrument ? "white" : "black",
            }}
            onClick={() => setPayWithInstrument(instrument)}>
            {/* <Box> */}
            <Box
              sx={{
                padding: "9px 10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                height: "auto",
                bgcolor: "white",
                borderRadius: "50%",
                boxShadow: "0px 0px 10px rgba(33, 109, 187, 0.18)",
              }}>
              <Box
                sx={{
                  backgroundImage: `url('${instrument.logoUrl}')`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  height: "40px",
                  width: "40px",
                  borderRadius: "20px",
                }}
                alt={instrument.gateway}>
              </Box>
            </Box>

            <Box
              direction="row"
              justifyContent="center"
              textAlign="center"
              paddingBottom="0.2rem">
              {instrument.instrumentName}
            </Box>
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

PaymentOptions.propTypes = {
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string.isRequired,
      instrumentCode: PropTypes.string.isRequired,
      logoUrl: PropTypes.string.isRequired,
      gateway: PropTypes.string.isRequired,
    })
  ),
  activeButton: PropTypes.string,
  payWithInstrument: PropTypes.object,
  setPayWithInstrument: PropTypes.func,
};

export default PaymentOptions;
