import React, { useMemo, useState } from "react";
import PayContext from "./payContext";

const PayState = (props) => {
  const [paymentInstrumentData, setPaymentInstrumentData] = useState(null);
  const updatePaymentInstrumentData = (newValue) => {
    setPaymentInstrumentData(newValue);
  };
  const paymentContext = useMemo(
    () => ({ paymentInstrumentData, updatePaymentInstrumentData }),
    [paymentInstrumentData]
  );

  return (
    <PayContext.Provider value={paymentContext}>
      {props.children}
    </PayContext.Provider>
  );
};

export default PayState;
