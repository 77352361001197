import "./App.css";
import MainRoute from "./app/routes/MainRoute";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  refetchOnWindowFocus: false,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MainRoute />
    </QueryClientProvider>
  );
}

export default App;
