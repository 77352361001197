import { Button, TextField, styled } from "@mui/material";

export const StyledButton = styled(Button)({
  height: "58px",
  display: "flex",
  width: "180px",
  marginRight: "10px",
  marginTop: "1rem",
  boxShadow: "0px 0px 10px rgba(33, 109, 187, 0.18)",

  // left: "13rem",
  borderRadius: "10px",
  backgroundColor: "#0D6EFD",
  color: "white",
  "&:hover": {
    backgroundColor: "rgba(139, 0, 0, 0.8)",
    color: "#FFF",
  },
});

export const StyledTextField = styled(TextField)({
  height: "51px",
  width: "361px",
});

export const trashImg = "../assets/images/trash_img.png";
