import React, { useMemo, useState } from "react";
import AppContext from "./appContext";

const AppState = (props) => {
  const [sharedData, setSharedData] = useState(null);
  const updateSharedData = (newValue) => {
    setSharedData(newValue);
  };

  const contextValue = useMemo(
    () => ({ sharedData, updateSharedData }),
    [sharedData]
  );

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;