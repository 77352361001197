import { StyledButton } from "../StyledComponents";
import { Container, Grid } from "@mui/material";
import PaymentOptions from "./PaymentOptions";
import { Bank, Card, CardCoin, Mobile, Wallet1 } from "iconsax-react";

const PaymentButtons = ({
  groupedInstruments,
  activeButton,
  setSelectedInstrumentType,
}) => {
  const dataMap = {
    CheckoutGateway: {
      name: "Wallets",
      icon: <Wallet1 />,
    },
    EBanking: {
      name: "E-Banking",
      icon: <Bank />,
    },
    MBanking: {
      name: "M-Banking",
      icon: <Mobile />,
    },
    checkoutcard: {
      name: "Card Payment",
      icon: <Card />,
    },
    checkoutcardapi: {
      name: "Online Card",
      icon: <CardCoin />,
    },
  };

  const InstrumentButton = ({ instrumentType, onClick }) => {
    return (
      <StyledButton
        onClick={onClick}
        sx={{
          display: "flex",
          justifyContent: "left",
          padding: "20px",
          backgroundColor:
            activeButton === instrumentType ? "#8B0000" : "white",
          color: activeButton == instrumentType ? "#FFFFF" : "#8B0000",
          width: {
            xs: 60,
            sm: 180,
            md: 180,
            lg: 180
          },

        }}
        size="small"
        startIcon={dataMap[instrumentType]?.icon}>
        <span className="paymentName">{dataMap[instrumentType]?.name}</span>
      </StyledButton>
    );
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ marginTop: "1rem" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          flexDirection={"column"}
          alignItems="flex-start">
          {Object.keys(groupedInstruments).map((instrumentType) => (
            <InstrumentButton
              key={instrumentType}
              instrumentType={instrumentType}
              onClick={() => setSelectedInstrumentType(instrumentType)}
            />
          ))}
        </Grid>
      </Container>
      <PaymentOptions activeButton={activeButton} />
    </>
  );
};

export default PaymentButtons;
