const queryParams = new URLSearchParams(window.location.search);
const type = queryParams.get("from");

let PaymentApi;
let CourseApi;

if (type === "fluential") {
  PaymentApi = {
    getWallets: "/api/fluential/wallets",
    applyCoupon: "/api/fluential/coupon",
    pay: "/api/fluential/pay"
  };
  CourseApi = {
    getCourseList: "/api/fluential/package-info"
  };
} else {
  PaymentApi = {
    getWallets: "/api/bridge/wallets",
    applyCoupon: "/api/bridge/coupon",
    pay: "/api/bridge/pay"
  };
  CourseApi = {
    getCourseList: "/api/package-info"
  };
}

export { PaymentApi, CourseApi };
