import React, { useState } from "react";
import "../styles/style.css";
import ProductTable from "./OrderSummary/ProductTable";
import { Box, Container, Typography } from "@mui/material";

const OrderSummary = ({ setShowPaymentMethods }) => {
  const [couponCode, setCouponCode] = useState(null);
  return (
      <Container className="order-summary" sx={{ padding: 0 }}>
        <Box >
          <ProductTable 
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            setShowPaymentMethods={setShowPaymentMethods}
          />
        </Box>
      </Container>
  );
};

export default OrderSummary;
