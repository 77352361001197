import React, { useState } from "react";
import OrderSummary from "../components/OrderSummary";
import PaymentMethods from "../components/PaymentMethods";
import { Grid } from "@mui/material";
import AppState from "../../context/AppState";
import PayState from "../../context/PayState";
import AmountState from "../../context/AmountState";

const Payment = () => {
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  return (
    <AmountState>
      <AppState>
        <PayState>
          <Grid container spacing={1} sx={{ width: "100%", maxHeight: "95vh" }}>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                padding: { xs: "0px", sm: "0" },
                margin: { xs: "0px", sm: "0" },
              }}
              className={`${showPaymentMethods
                  ? "hide-payment-methods"
                  : "show-payment-methods"
                }`}
            >
              <OrderSummary
                showPaymentMethods={showPaymentMethods}
                setShowPaymentMethods={setShowPaymentMethods}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              className={`${showPaymentMethods
                  ? "show-payment-methods"
                  : "hide-payment-methods"
                }`}
            >
              <PaymentMethods
                showPaymentMethods={showPaymentMethods}
                setShowPaymentMethods={setShowPaymentMethods}
              />
            </Grid>
          </Grid>
        </PayState>
      </AppState>
    </AmountState>
  );
};

export default Payment;
