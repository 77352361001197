import React, { useMemo, useState } from "react";
import AmountContext from "./amountContext";


const AmountState = (props) => {
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const updateDiscountedAmount = (newValue) => {
    setDiscountedAmount(newValue);
  };

  const discountValue = useMemo(
    () => ({ discountedAmount, updateDiscountedAmount }),
    [discountedAmount]
  );

  return (
    <AmountContext.Provider value={discountValue}>
      {props.children}
    </AmountContext.Provider>
  );
};

export default AmountState;