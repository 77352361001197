import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import "../styles/style.css";
import PaymentButtons from "./PaymentMethods/PaymentButtons";
import PaymentOptions from "./PaymentMethods/PaymentOptions";
import AppContext from "../../context/appContext";
import PayContext from "../../context/payContext";
import { ArrowRight2, Trash } from "iconsax-react";
import { submitPayment } from "../../data/SubmitPayment";
import LoadingSpinner from "./Loader/loader";
import AmountContext from "../../context/amountContext";
import { PaymentApi } from "../../data/apiUrls";

const PaymentMethods = ({ setShowPaymentMethods, showPaymentMethods }) => {
  const [payWithInstrument, setPayWithInstrument] = useState({});
  const { paymentInstrumentData, updatePaymentInstrumentData } =
    useContext(PayContext);

  const [groupedInstruments, setGroupedInstruments] = useState({});
  const [selectedInstrumentType, setSelectedInstrumentType] =
    useState("CheckoutGateway");
  const { sharedData } = React.useContext(AppContext);
  const { discountedAmount } = useContext(AmountContext);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_.BASE_URL}${PaymentApi.getWallets}`
    );
    const data = await response.json();
    data && setLoading(false);
    return data;
  };
  useEffect(() => {
    fetchData()
      .then((data) => {
        const groups = {};
        data.forEach((instrument) => {
          if (!groups[instrument.instrumentType]) {
            groups[instrument.instrumentType] = [];
          }
          groups[instrument.instrumentType].push(instrument);
        });
        setGroupedInstruments(groups);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    updatePaymentInstrumentData(payWithInstrument);
  }, [payWithInstrument]);

  return (
    <Container className="order-summary" sx={{ padding: 0 }}>
      <Box
        sx={{
          boxShadow: "0px 0px 10px rgba(33, 109, 187, 0.18)",
          // padding:{xs: "0", sm:"40px", md:"40px", lg: "40px"},
          borderRadius: "5px",
          height: "90vh",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ paddingTop: "20px" }}
        >
          <Typography
            variant="h5"
            gutterBottom
            display="block"
            className="order-summary-header"
          >
            Select Payment Method
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            gap={"6px"}
          >
            <Typography variant="h6">Rs</Typography>
            <Typography
              variant="h5"
              sx={{ color: "#8B0000", fontWeight: "bold", marginRight: "16px" }}
            >
              {Math.ceil(sharedData?.amount)}
              {/* {discountedAmount} */}
            </Typography>
          </Box>
        </Box>
        <Box height="80vh">
          <Box display={"flex"} gap={"6px"} maxHeight="80vh">
            <Box>
              <PaymentButtons
                groupedInstruments={groupedInstruments}
                activeButton={selectedInstrumentType}
                setSelectedInstrumentType={setSelectedInstrumentType}
                setPayWithInstrument={setPayWithInstrument}
                payWithInstrument={payWithInstrument}
              />
            </Box>
            <Box
              sx={{
                overflowY: "scroll",
                minWidth: "72%",
              }}
            >
              {selectedInstrumentType && (
                <PaymentOptions
                  instruments={groupedInstruments[selectedInstrumentType]}
                  activeButton={selectedInstrumentType}
                  setPayWithInstrument={setPayWithInstrument}
                  payWithInstrument={payWithInstrument}
                />
              )}
            </Box>
          </Box>
          <Box>
            <Box
              className="mobile-button-container"
              sx={{
                marginLeft: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: {
                    xs: 156,
                    md: 230,
                    lg: 460,
                    xl: 460,
                  },
                  marginLeft: "12px",
                  marginTop: "10px",
                }}
                onClick={(e) => {
                  setShowPaymentMethods(false);
                }}
                disabled={!showPaymentMethods}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: {
                    xs: 156,
                    md: 230,
                    lg: 460,
                    xl: 460,
                  },
                  marginLeft: "12px",
                  marginTop: "10px",
                  marginButton: "10px",
                }}
                onClick={() => {
                  submitPayment(payWithInstrument, sharedData);
                  setLoading(true);
                }}
                disabled={
                  payWithInstrument?.gateway?.length === undefined ||
                  discountedAmount == 0
                }
              >
                Pay
                <ArrowRight2 />
              </Button>
            </Box>
          </Box>
          {loading && (
            <div
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.2)", // Set the background color to semi-transparent black
                zIndex: "9999",
              }}
            >
              <LoadingSpinner />
            </div>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default PaymentMethods;
