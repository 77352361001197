import { Instance } from "../config/axiosConfig";
import { PaymentApi } from "./apiUrls";

export const submitPayment = async (payWithInstrument, sharedData) => {
  try {
    const { data } = await Instance.post(PaymentApi.pay, {
      user_id: sharedData.user_id,
      package_id: sharedData.package_id[0],
      // package_id: "44,100",
      from: sharedData.from,
      planType: sharedData.planType,
      amount:sharedData.amount,
      coupon: sharedData.coupon,
      course_data: sharedData.course_data,
      instrumentCode: payWithInstrument.instrumentCode,
      gateway: payWithInstrument.gateway,
      remarks: `${sharedData.planType}:${sharedData.package_id[0]}, ${sharedData.coupon}, ${sharedData.amount}, ${payWithInstrument.gateway}:${payWithInstrument.instrumentCode}`,
    });

    document.open();
    document.write(data);
    document.close();
  } catch (error) {
    console.log("error", error);
  }
};
